import eachElement from 'Utils/eachElement.js';
import errorHandler from 'Utils/errorHandler.js';
import whenAvailable from 'Utils/whenAvailable.js';

/* Close all modals */
const closeModals = event => {
	if (event) {
		event.stopPropagation();
	}

	eachElement('.modal', modal => {
		modal.classList.remove('is-active');
	});

	eachElement('.modal-content > div aside', modalContent => {
		modalContent.classList.remove('is-concealed');
	});
};

/* Open a modal by ID */
const openModal = modalId => {
	/* Get all modal IDs on the page */
	const modalElements = document.querySelectorAll('.modal');
	const modalIds = Array.from(modalElements).map(modal => modal.id);

	/* Check if the provided modalId exists in the list of modal IDs */
	if (!modalIds.includes(modalId)) {
		errorHandler(new Error(`Tried to open non-existent modal ID: ${modalId}`));
		return;
	}

	const modal = document.querySelector(`#${modalId}`);

	if (modal) {
		/* Display modal */
		modal.classList.add('is-active');

		/* Prefill email address, if supplied */
		const urlParameters = new URLSearchParams(window.location.search);
		const email = urlParameters.get('email');
		const emailInput = modal.querySelector('input[name="email"]');

		if (email && emailInput) {
			emailInput.value = email;
			emailInput.focus();
		}
	}
};

/* Set up all modals and triggers on the page */
const setUpModals = () => {
	/* Set up modal triggers */
	eachElement('[data-modal]', trigger => {
		trigger.addEventListener('click', event => {
			event.preventDefault();
			openModal(trigger.dataset.modal);
		});
	});

	/* Set up modals */
	eachElement('.modal', modal => {
		const modalBackground = modal.querySelector('.modal-background');
		const aside = modal.querySelector('aside');
		const main = modal.querySelector('main');

		const clickableElements = new Set([aside, main, modalBackground]);

		/* Check that the content clicked is not the content within the modal */
		function isOutsideModal(event) {
			if (clickableElements.has(event.target)) {
				closeModals();
			}
		}

		window.addEventListener('keydown', event => {
			if (event.key === 'Escape') {
				closeModals();
			}
		});

		modal.addEventListener('mousedown', isOutsideModal);
		modal.querySelector('.modal-close')?.addEventListener('click', closeModals);
	});

	/* Set up tabs in modals, if available */
	eachElement('.modal-content > .tabs', modalContent => {
		const modalTabs = modalContent.querySelectorAll('li[data-target] > a');
		const modalLiveChatButton = modalContent.querySelector('[data-intercom-trigger="true"]');

		/* Reveal tab content when clicking the tabs on mobile */
		for (const tab of modalTabs) {
			tab.addEventListener('click', () => {
				modalContent.querySelector('aside').classList.add('is-concealed');
			});
		}

		/* Bring tab options back when clicking the back button on mobile */
		modalContent.querySelector('.modal-back').addEventListener('click', () => {
			modalContent.querySelector('aside').classList.remove('is-concealed');
		});

		whenAvailable('Intercom')
			.then(() => {
				/* Close the modal when clicking the live chat button */
				modalLiveChatButton?.querySelector('a').addEventListener('click', event => {
					event.preventDefault();
					/* eslint-disable-next-line new-cap */
					window.Intercom('showNewMessage');
					closeModals(event);
					return false;
				});
			})
			.catch(() => {
				/* Hide live chat button if Intercom failed to load */
				modalLiveChatButton?.remove();
				errorHandler(new Error('Intercom failed to load in time for modal'));
			});
	});

	/* Open modals from the URL */
	const urlParameters = new URLSearchParams(window.location.search);
	const modalParameter = urlParameters.get('modal');

	if (modalParameter) {
		openModal(`${modalParameter}-modal`);

		/* Remove the modal from the URL */
		const url = new URL(window.location);
		url.searchParams.delete('modal');
		url.searchParams.delete('email');
		window.history.replaceState({}, '', url);
	}
};

export default {
	setUpModals,
};
