import eachElement from 'Utils/eachElement.js';
import errorHandler from 'Utils/errorHandler.js';
import whenAvailable from 'Utils/whenAvailable.js';

/* Ensure the element is empty */
const emptyElement = element => {
	while (element.firstElementChild) {
		element.firstElementChild.remove();
	}
};

/* Track whether the user has interacted with Calendly in any way */
const trackInteraction = element => {
	const widgetType = getWidgetType(element);

	/* Slightly different event types, as we don't want to track hovering on top of a popup trigger */
	const events = widgetType === 'inline' ? ['click', 'touch', 'mouseover', 'touchmove'] : ['click', 'touch', 'mousedown'];

	/* Create a listener for all appropriate events */
	for (const event of events) {
		element.addEventListener(event, () => {
			window.hasInteractedWithCalendly = true;
		});
	}
};

/* Event handler for redirecting to Calendly */
const redirectToCalendly = event => {
	const url = getCalendlyURL(event.target);

	if (url) {
		location.href = url;
	}
};

/* Get the type of widget ("inline" or "popup") */
const getWidgetType = element => String(element.dataset.widgetType).toLowerCase() === 'inline' ? 'inline' : 'popup';

/* Construct the Calendly calendar URL based on element attributes */
const getCalendlyURL = element => {
	const url = new URL(`/gearset/${element.dataset.calendly}?hide_gdpr_banner=1`, 'https://calendly.com/');

	if (element.dataset.hideInfo) {
		url.searchParams.append('hide_event_type_details', '1');
	}

	return url.toString();
};

/* Construct Calendly settings object */
const getCalendlySettings = element => {
	const widgetType = getWidgetType(element);

	/* Set URL */
	const settings = {
		url: getCalendlyURL(element),
	};

	/* Add Ruler, if available */
	if ('RulerAnalyticsVisitorId' in window) {
		settings.utm = {
			utmContent: window.RulerAnalyticsVisitorId,
		};
	}

	/* Add inline specific settings, if appropriate */
	if (widgetType === 'inline') {
		settings.parentElement = element;
		settings.prefill = {};
		settings.resize = element.dataset.autoResize === 'true';
	}

	return settings;
};

/* Initialise Calendly */
const initCalendly = element => {
	/* Prep the element */
	trackInteraction(element);
	element.removeEventListener('click', redirectToCalendly);

	/* Get Calendly settings */
	const settings = getCalendlySettings(element);
	const widgetType = getWidgetType(element);

	if (widgetType === 'inline' && element) {
		/* Clear any previous Calendly iframe */
		emptyElement(element);

		/* Set up inline Calendly (e.g. book a demo modal) */
		try {
			window.Calendly.initInlineWidget(settings);
		} catch (error) {
			errorHandler(error);
		}
	} else {
		/* Open Calendly pop-in calendar on trigger */
		const handleClick = event => {
			event.preventDefault();

			try {
				window.Calendly.initPopupWidget(settings);
			} catch (error) {
				/* Log the error */
				errorHandler(error);

				/* Send the user to Calendly as a fallback */
				redirectToCalendly(event);
			}
		};

		/* Set up button events (remove any existing one) */
		element.removeEventListener('click', handleClick);
		element.addEventListener('click', handleClick);
	}
};

/* Handle user communication and error logging if Calendly failed to load */
const handleFallback = element => {
	/* Get Calendly settings */
	const widgetType = getWidgetType(element);

	if (widgetType === 'inline' && element) {
		/* Clear any previous iframe */
		emptyElement(element);

		/* Communicate to user */
		element.innerHTML = '<div><p class="notification is-red my-6">Sorry, we\'re having some trouble loading our calendar right now. Please reach out to us through the chat in the bottom corner of the screen!</p></div>';

		/* Log the error */
		errorHandler(new Error('Calendly failed to load in time for inline scheduler'));
	} else {
		/* Send user to Calendly's hosted URL as a fallback */
		element.addEventListener('click', redirectToCalendly);
	}
};

/* Initialise Calendly on the page */
const setUpCalendly = () => {
	/* Whether the user has already interacted with the Calendly iframe or not */
	window.hasInteractedWithCalendly = false;

	/* For each Calendly trigger on the page */
	eachElement('[data-calendly]', element => {
		/* If it's not inline, set an initial click handler */
		const widgetType = getWidgetType(element);
		if (widgetType !== 'inline') {
			element.addEventListener('click', redirectToCalendly);
		}

		/* Load Calendly initially */
		whenAvailable('Calendly')
			.then(() => initCalendly(element))
			.catch(() => handleFallback(element));

		/* Re-load once/if Ruler also becomes available */
		whenAvailable(['Calendly', 'RulerAnalyticsVisitorId'])
			.then(() => {
				if (!window.hasInteractedWithCalendly) {
					initCalendly(element);
				}
			})
			.catch(() => {
				if (!window.hasInteractedWithCalendly) {
					handleFallback(element);
				}
			});
	});
};

export default {
	setUpCalendly,
};
